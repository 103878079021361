
import { defineComponent } from "vue";

import {
  relevantRecordsDetailCN,
  relevantRecordsDetailEN
} from "./RelevantExperience";

export default defineComponent({
  setup() {
    let detailArray: Array<string> = [];

    return {
      relevantRecordsDetailCN,
      relevantRecordsDetailEN,
      detailArray
    };
  }
});
